
// メタデータ
export const bustArr= ["未設定", "A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L"]
export const rootUri = "https://t-chulip-girls.com"
export const apiRoot = "https://api.t-chulip-girls.com/api"
export const diaryApiRoot = "https://api.marineblue-g.com/api"

// ヘブンの店舗topページ 各種連動に使う
export const hvnUriRoot = "https://www.cityheaven.net/ibaraki/A0802/A080202/tulipgirls_t"

// ヘブンブログパーツ類
export const hvnShopId = "2510023329";

export const hvnApi = {
    Diary:   "https://blogparts.cityheaven.net/widget/?shopId="+ hvnShopId +"&mode=2&type=20&limitedKind=0&num=8",
    Video:   "https://blogparts.cityheaven.net/widget/?shopId="+ hvnShopId +"&mode=4&type=14&num=5&col=3&color=2&fontcolor=10&btncolor=6&fontsize=15",
    Comment: "https://blogparts.cityheaven.net/widget/?shopId="+ hvnShopId +"&mode=8&type=18&num=5&color=8&fontcolor=10&btncolor=6&fontsize=15",
}

// 店舗情報
export const shopInfo = {
    name:       "チューリップ 土浦店",
    tel:        "029-846-0317",
    openTime:   "6:00~24:00",
    zipCode:    "300-0037",
    zipAddress: "茨城県土浦市桜町 2丁目4-10",
    gmapUri:    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d806.1640429262449!2d140.19936606958336!3d36.07748958218857!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60226d4b72b62df7%3A0x145425874bc5138e!2z44CSMzAwLTAwMzcg6Iyo5Z-O55yM5Zyf5rWm5biC5qGc55S677yS5LiB55uu77yU4oiS77yR77yQ!5e0!3m2!1sja!2sjp!4v1693540780720!5m2!1sja!2sjp",
};

export const priceChart = "/img/20240716-price.jpg"
export const priceChart0807 = "/img/20241002-price.jpg"

// 20240807 料金表タイマー関数追加 system.js側も参照
export const getPriceChart=(triggerDate)=>{

    const trigger = new Date(triggerDate)
    const now = new Date()

    /* 動作確認する時にCO消して使ってください.
    console.log("現在時刻: ")
    console.log(now)
    console.log("表示差し替え時刻: ")
    console.log(trigger)
    */

    if(now.getTime() > trigger.getTime()){

        return priceChart0807

    }
    return priceChart
}

export const areaName = "土浦"

/* 以下、ページ内表示物など */

export const linkNavs = [
        {name: "ホーム",          path: "/home", blank: false, img: "/img/ico/i-home.png"},
        {name: "出勤表",          path: "/schedule", blank: false, img: "/img/ico/i-schedule.png"},
        {name: "新人一覧",        path: "/newface", blank: false, img: "/img/ico/i-newface.png"},
        {name: "在籍一覧",        path: "/allcasts", blank: false, img: "/img/ico/i-rec-women.png"},
        {name: "料金システム",     path: "/system", blank: false, img: "/img/ico/i-price.png"},
        {name: "アクセス", path: "/access", blank: false, img: "/img/ico/i-map.png"},
        {name: "写メ日記", path: `${hvnUriRoot}/diarylist`, blank: true, img: "/img/ico/i-diary.png"},
        {name: "動画一覧", path: `${hvnUriRoot}/shopmovie`, blank: true, img: "/img/ico/i-video.png"},
        {name: "女子求人", path: "https://kanto.qzin.jp/tulipgt/?v=official", blank: true, img: "/img/ico/i-rec-women.png",
         tagForced: true,  requireTag: "vanilla"},
        // {name: "男子求人", path: "https://mens-qzin.jp/tochigi/area_09001/detail/utsunomiyatulipgirls/?v=official", blank: true, img: "/img/ico/i-rec-men.png"},
];


export const slideArr = [
    {
     link: {
        blank: false,
        uri: null },
     img: {
        alt: "お待たせしました！土浦店 NEW OPEN! マリングループ チューリップ", 
        imgPathSp: "https://api.marineblue-g.com/api/topbanner?t=t_chulip_girls", },
    },
    {
     link: {
        blank: true,
        uri: "https://men.marineblue-g.com" },
     img: {
        alt: "店舗急拡大中につき男女スタッフ大募集 風俗店舗スタッフ未経験月給50万~！経験者月給～80万円！日払いOK！", 
        imgPathSp: "/img/20231019-s-rec.jpg", },
    },
    /*
    {
     link: {
        blank: false,
        uri: null },
     img: {
        alt: "", 
        imgPathSp: "/", 
        imgPathPc: "", },
    },
    */
];